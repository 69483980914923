<template>
  <v-col cols="auto" md="4">
    <v-card
      border
      class="ml-0 mr-0 pr-6"
      :style="{
        borderRadius: '10px',
        margin: '4px',
        backgroundColor: $cv('primary') + '20',
      }"
      ><p class="text-h4 pt-4 text-center" style="line-height: 1.5">
        <b class="">Resumen del pedido</b>
      </p>
      <hr class="ml-5" />
      <div v-if="carrito.length == 0" class="carritoMsg__div">
        <p>Sin Productos en el carrito</p>
        <p>Seleccione los Productos que desea comprar</p>
      </div>

      <div class="carrito__div">
        <div v-for="(item, index) in carrito" :key="index" class="py-2 px-3">
          <div class="carrito__descripcion__container">
            <div class="carrito__descripcion__texto">
              <p class=" ma-0 pa-0 pl-2 text-start">
                {{ item.nombre }}
              </p>
              <p
                v-if="modSaldo"
                class=" ma-0 pa-0 pl-2 pr-2 text-start p_carrito"
              >
                {{
                  item.precio
                    ? "Precio por Unidad $" + $formatMoney(item.precio)
                    : ""
                }}
              </p>
              <p
                v-else
                class=" ma-0 pa-0 pl-2 pr-2 text-center p_carrito"
              >
                {{ item.precio ? "Monto: $" + $formatMoney(item.precio) : "" }}
              </p>
              <p
                v-if="modSaldo"
                class=" ma-0 pa-0 pl-2 pr-20 text-start p_carrito"
              >
                Cantidad: {{ item.cantidad }}
              </p>
            </div>
            <div class="carrito__descripcion__icon">
              <v-icon
                v-if="modSaldo"
                title="Editar"
                @click="$emit('editarCarrito', item)"
                >mdi-pencil-box</v-icon
              >
              <v-icon title="Eliminar" @click="eliminarProducto(item)"
                >mdi-trash-can</v-icon
              >
            </div>
          </div>
          <p v-if="modSaldo" class=" mb-2 pl-2 pr-2 text-end">
            {{
              item.subtotal
                ? "Subtotal: $" + $formatMoney(item.subtotal)
                : "Subtotal: $" + $formatMoney(item.precio)
            }}
          </p>
          <hr v-if="index + 1 < carrito.length" class="ml-5" />
        </div>
      </div>

      <div v-show="carrito.length != 0">
        <hr class="ml-5" />
        <div class="div_total">
          <router-link :to="routeDetalle"
          >
            <BaseButton
              color="btnPrimary"
              size="small"
            >
              Continuar
            </BaseButton>
          </router-link>
          <p v-if="modSaldo" class=" py-4 my-0 text-end">
            <b>Total: $ {{ total ? $formatMoney(total) : "" }}</b>
          </p>
          <p v-else class=" py-4 my-0 text-end">
            <b>Total: $ {{ totalSCC ? $formatMoney(totalSCC) : totalSCC }}</b>
          </p>
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { BaseButton } from "../../../../components/design-system";

export default {
  components: {
    BaseButton,
  },

  data() {
    return {
      routeDetalle: "",
      modSaldo: true,
      totalSCC: 0,
    };
  },
  computed: {
    ...mapState("carrito", ["carrito", "total"]),
  },

  methods: {
    ...mapMutations("carrito", ["removeProducto"]),

    adquirir_saldo_cta_cte() {
      if (this.$route.path == "/adquirir_saldo_cta_cte") {
        this.modSaldo = false;
      }
    },

    eliminarProducto(item) {
      Swal.fire({
        title: `Desea eliminar el Producto?`,
        text: `${item.cantidad} Unidades de ${item.nombre}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.removeProducto(item);
          Swal.fire({
            title: `Se han Eliminado ${item.cantidad} Unidades de "${item.nombre}"`,
            icon: "success",
          });
        }
      });
    },
  },
  mounted() {
    console.log("route en carrito");
    console.log(this.$route.path);
    this.adquirir_saldo_cta_cte();
    switch (this.$route.path) {
      case "/adquirir_saldo_cta_cte":
        this.routeDetalle = "/detalle_pedido_adquirir_saldo";
        break;
      case "/estampillas_Anmac_Venta_productos":
        this.routeDetalle = "/detalle_estampillas_venta_detalle_pedido";
        break;
      case "/elementos_aduana_compra":
        this.routeDetalle = "/elementos_aduana_compra_detalle_pedido";
        break;
      case "/elementos_aduana_venta_seleccion":
        this.routeDetalle = "/elementos_aduana_venta_detalle_pedido";
        break;
      default:
        //this.routeDetalle = "/detalle_pedido";
        this.routeDetalle = {
          name: "detalle_pedido",
          params: {
            previousPath: this.$route.path,
          },
        };
        break;
    }
  },
  watch: {
    carrito() {
      this.totalSCC = 0;
      this.carrito.forEach((item) => {
        this.totalSCC = this.totalSCC * 1 + item.precio * 1;
      });
    },
  },
};
</script>

<style scoped>
.carritoMsg__div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 9rem;
  font-weight: bold;
  font-size: 0.85rem;
}
.carrito__div {
  overflow-y: auto;
  max-height: 41rem;
}
.carrito__descripcion__container {
  display: flex;
}
.carrito__descripcion__texto {
  width: 80%;
  min-width: 80%;
}
.p_carrito {
  line-height: 1.5rem;
}
.carrito__descripcion__icon {
  display: flex;
  justify-content: space-around;
  width: 20%;
  min-width: 20%;
}
.div_total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.routerLink_total {
  background-color: #011f5b;
  color: white !important;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  text-decoration: none;
  border-radius: 0.5rem;
}
</style>
