<template>
  <v-col sm="1">
    <v-dialog v-model="dialog" max-width="70%" persistent>
      <v-card>
        <v-form ref="form" v-model="valid">
          <v-toolbar dark :color="$cv('principal')">
            <v-btn icon dark @click="$emit('terminar')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ action }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="save" :disabled="!valid">Guardar</v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row class="flex justify-content-between">
                <v-col cols="12" md="5" sm="12">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Nombre"
                    autofocus
                    :rules="[$rulesRequerido]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                  <v-text-field
                    v-model="editedItem.documento"
                    label="Documento"
                    :rules="[$rulesRequerido, $rulesDni]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-text-field
                    v-model="editedItem.city"
                    label="Ciudad"
                    :rules="[$rulesRequerido]"
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row class="flex justify-content-between">
                <v-col cols="12" md="5" sm="12">
                  <v-text-field
                    v-model="editedItem.phone"
                    label="Celular"
                    :rules="[$rulesRequerido, $rulesTelefono]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="7" sm="12">
                  <v-text-field
                    v-model="editedItem.email"
                    label="Email"
                    :rules="[$rulesRequerido, $rulesMail]"
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
      default: "",
    },
  },

  data() {
    return {
      editedItem: {
        nombre: "",
        id: "",
      },
      valid: true,
      dialog: true,
      readonly: true,
      readOnlySubtotal: true,
    };
  },

  computed: {
    ...mapState("anmac", ["clienteAnmac"]),
  },

  methods: {
    ...mapMutations("anmac", ["addCliente", "editCliente"]),

    save() {
      if (this.action == "REGISTRAR CLIENTE"){
        console.log('guardado');
      this.addCliente(this.editedItem);
      return this.$emit("terminar");  
      }
      
      if (this.action == "EDITAR CLIENTE"){
        console.log('editado');
        this.editCliente(this.editedItem);
        return this.$emit("terminar");  
      }
    },
  },

  created() {
    this.editedItem = this.item;
    this.dialog = true;
  },
  mounted() {},

  destroyed() {},
};
</script>

<style scoped></style>
