<template>
  <v-container fluid pt-1>
    <v-card class="elevation-2 mb-4 mt-0 p-4" v-if="topRow && topRowArray && topRowArray.length">
      <table class="topRow__table">
        <tr>
          <td v-for="(item, index) in topRowArray" :key="index" class="topRow__table__item">
            <span v-if="!item.btnPath">
              <b> {{ item.text }}: </b>{{ item.value }}
            </span>
            <span v-else>
              <router-link :to="item.btnPath">
                <BaseButton
                  color="btnSecondary"
                  size="small"
                  class="mx-0 my-0"
                >
                  {{ item.btnText }} &nbsp;
                  <v-icon :color="item.btnIconColor || 'white'" size="medium">{{ item.btnIcon }}</v-icon>
                </BaseButton>
              </router-link>
            </span>
          </td>
        </tr>
      </table>
    </v-card>
    <v-data-table
      v-model="data"
      :headers="headers"
      :items="items"
      :search="search"
      :item-key="item_key"
      :show-select="showSelect"
      :single-select="true"
      sort-by="secret"
      class="elevation-4 data__table custom-data-table"
    >
      <template v-if="action" v-slot:[`item.actions`]="{ item }">
        <v-icon small :color="$cv('btnEditar')" title="Agregar" @click="$emit('actionRtn', item)">
          mdi-plus-box
        </v-icon>
      </template>

      <template v-slot:top>
        <v-toolbar v-if="buscador" flat color="white" class="pr-2 mt-4">
          <v-row class="flex justify-content-between">
            <v-col :cols="searchcols" class="pt-4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="'Busqueda de ' + title"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col v-if="topBarBtn != ''" cols="2" class="pt-2 m-0 vtable__btn">
              <BaseButton color="primary" size="small" @click.prevent="$emit('topBarBtnAcc')">
                {{ topBarBtn }} &nbsp;
              </BaseButton>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import BaseButton from "@/components/design-system/base/Button.vue";

export default {
  components: {
    BaseButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    buscador: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
      required: true,
    },
    topBarBtn: {
      type: String,
      default: "",
    },
    action: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    item_key: {
      type: String,
      default: undefined,
    },
    topRow: {
      type: Boolean,
      default: false,
    },
    topRowArray: {
      type: Array,
    },
  },

  data: (vm) => ({
    titlePerms: "estampillas_anmac",
    data: [],
    dataselect: {},
    cliente: [],
    search: "",
    searchcols: "",
  }),

  methods: {
    eventShow_Select() {
      console.log(this.showSelect);
      if (this.show_Select == false) {
        console.log(this.itemModel[0]);
      }
    },
  },

  watch: {
    data: function () {
      if (this.showSelect) {
        this.dataselect = this.data[0];
        return this.$emit("dataselect", this.dataselect);
      }
    },
  },

  mounted() {
    console.log(this.topBarBtn);
    console.log(this.$can(["estampillas_anmac_add_client"]));
    this.searchcols = this.topBarBtn === "" ? "12" : "10";
  },
};
</script>

<style>
.data__table {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 52.75rem;
}

.topRow__table {
  width: 100%;
  border-collapse: collapse;
}

.topRow__table__item {
  padding: 0.5rem;
  text-align: center;
}

.topRow__table__btn {
  text-align: center;
}

/* Añade padding interno al card */
.p-4 {
  padding: 0.5rem;
}

.v-data-table .v-data-table__selected .v-data-table__checkbox .v-icon {
  color: var(--v-primary-base) !important; /* Borde del checkbox */
}

/* Eliminar el fondo de la fila seleccionada */
.v-data-table .v-data-table__selected {
  background-color: transparent !important;
}
</style>
